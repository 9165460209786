import { Row, Col, Spinner } from "reactstrap";
import "./ServicesList.css";
import { Zoom } from "react-awesome-reveal";

const ServiceList = ({ services, types, loading }) => {
  const servicesByType = types.reduce((acc, type) => {
    acc[type] = services.filter((service) => service.service_type === type);
    return acc;
  }, {});

  const serviceList = types.map((type) => {
    // match the type to the services
    const services = servicesByType[type];
    return (
      <>
        <Zoom cascade={true} triggerOnce={true}>
          <Col className="d-flex flex-row" key={type}>
            <Col className="px-0">
              <h2 className="text-dark text-capitalize service-type my-4 mb-3">
                {type}
                <span className="text-danger"> *</span>
              </h2>
            </Col>
          </Col>
          <Col className="d-flex flex-row">
            <Col xs={12} sm={12} className="px-0">
              {services.map((service) => {
                return (
                  <Col
                    key={service.service_id}
                    className="d-flex flex-row justify-content-between"
                  >
                    <p className="text-capitalize text-start text-dark service-text">
                      {service.service_name}
                    </p>
                    <p className="service-text text-dark">
                      ${service.service_price} & up
                    </p>
                  </Col>
                );
              })}
            </Col>
          </Col>
        </Zoom>
      </>
    );
  });

  return (
    <>
      <Row className="service-row p-2 p-sm-3 m-0 m-2 m-md-3">
        <Col xs={12} md={10} className="m-auto">
          <Zoom cascade={true} triggerOnce={true}>
            <h1 className="my-4 text-light">Salon Menu</h1>
          </Zoom>
        </Col>
        <Col
          xs={12}
          md={10}
          className="mx-auto d-flex flex-column justify-content-around mb-2 service-holder"
        >
          {loading && (
            <Col className="mx-auto my-3 text-dark">
              <Spinner
                color="dark"
                type="grow"
                className="align-self-auto text-light"
              >
                Loading...
              </Spinner>
            </Col>
          )}
          {services && (
            <>
              <Col className="d-flex flex-row justify-content-around mb-2">
                <Col className="p-0 p-md-5 ">{serviceList}</Col>
              </Col>
              <Col className="d-flex flex-row justify-content-around">
                <p className="p-2 text-danger fw-bold">
                  <em>*Services are a la carte.</em>
                  <br />
                  <em>
                    *Prices are subject to additional charges depending on hair
                    length, density, and texture.
                  </em>
                </p>
              </Col>
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

export default ServiceList;
