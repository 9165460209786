import ServiceList from "../components/services/ServiceList";
import { SEO } from "../shared/SEO";

const Services = ({ services, types, loading }) => {
  return (
    <div className="container-fluid w-100 p-1 p-md-3">
      <SEO
        title="Colour Mi' Chic Hair Salon | Salon Services"
        description="description"
        content="List of Colour Mi' Chic services and prices"
      />
      <ServiceList services={services} types={types} loading={loading} />
    </div>
  );
};

export default Services;
