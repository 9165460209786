import { useState } from "react";
import { SALON } from "../../shared/images";
import { Col, Carousel, CarouselItem, CarouselControl } from "reactstrap";
import { Zoom } from "react-awesome-reveal";
import "./AboutGallery.css";

const AboutGallery = () => {
  const items = SALON;

  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const slides = items.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.mediaUrl}
        className=" p-0 p-sm-2 p-md-3"
      >
        <img
          src={process.env.PUBLIC_URL + item.mediaUrl}
          alt={item.title}
          className="img-fluid about-img"
          // style={{ height: "600px" }}
        />
      </CarouselItem>
    );
  });

  return (
    <Col md={10} className=" p-2 p-md-3 salon-img-wrapper mx-auto">
      <Zoom triggerOnce={true}>
        <Carousel
          dark
          fade={true}
          activeIndex={activeIndex}
          next={next}
          previous={previous}
          // className="img-wrapper"
        >
          {/* <CarouselIndicators
          items={items}
          activeIndex={activeIndex}
          onClickHandler={goToIndex}
        /> */}
          {slides}
          <CarouselControl
            direction="prev"
            directionText="Previous"
            onClickHandler={previous}
          />
          <CarouselControl
            direction="next"
            directionText="Next"
            onClickHandler={next}
          />
        </Carousel>
      </Zoom>
    </Col>
  );
};

export default AboutGallery;
