import { Row, Col } from "react-bootstrap";
import AboutGallery from "./AboutGallery";
import { Zoom } from "react-awesome-reveal";
import "./Info.css";

/* Will need to make a color me Chic title and place it where it can be reused */

const Info = () => {
  return (
    <>
      <Row className="about-row p-2 p-sm-3 m-0 m-2 m-md-3">
        <Zoom triggerOnce={true}>
          <h1 className="my-4 text-light text">The Salon</h1>
        </Zoom>
        <AboutGallery />

        <Col xs={12} md={10} className="text-start mx-auto text-light my-3">
          <Zoom cascade={true} triggerOnce={true}>
            <p className="content">
              Welcome to{" "}
              <span className="fw-bold">
                Colour Mi
                <em className="info-char">&rsquo;&nbsp;</em>
                Chic
              </span>
              , where every experience is unique and{" "}
              <span className="mb-0 chic-sm-text fw-bold">
                Tailor&rsquo;d 2 U&#174;
              </span>
              . Our hair salon is a place where we specialize in color, cuts and
              hair care. Each client is a VIP. We work with our clients and use
              premium products and tools to create a look that is a reflection
              of their personality and style, while educating them on how to
              maintain healthy hair and styling options.
            </p>
          </Zoom>
        </Col>

        <Col xs={12} md={10} className="text-start text-light mx-auto">
          <Zoom cascade={true} triggerOnce={true}>
            <p className="content">
              <span className="fw-bold">
                Colour Mi<em className="info-char">&rsquo;&nbsp;</em> Chic
              </span>{" "}
              is owned and operated by Nikki Davis. Nikki hales from the NY/NJ
              area and has been in the beauty industry for over 20 years. As a
              licensed cosmetologist, she has trained with some of the best in
              the industry, has worked with some of the top brands in the hair
              and beauty industry and has been an educator. She combines her
              knowledge and talent with her love for the bright lights, glamour
              and flair of her brick city origins to create a salon experience
              that is unique and unforgettable.
            </p>
          </Zoom>
        </Col>
        <Col xs={12} md={10} className="text-start text-light mx-auto">
          <Zoom cascade={true} triggerOnce={true}>
            <p className="content">
              Nikki's love for hair has led her to specialize in color, cuts and
              haircare. She believes that teaching her clients to care for their
              hair, which products to use and how to use specific tools is just
              as important as creating a look that is a reflection of their
              personality and style. She is a firm believer in continuing
              education and is always looking for ways to improve her skills and
              knowledge.
            </p>
          </Zoom>
        </Col>
      </Row>
    </>
  );
};

export default Info;
