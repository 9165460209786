import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import { Zoom } from "react-awesome-reveal";
import "./SalonPolicies.css";

const SalonPolicies = () => {
  return (
    <>
      <Row className="policy-row p-2 p-sm-3 m-0 m-2 m-md-3">
        <Zoom cascade={true} triggerOnce={true}>
          <h1 className="my-4 text-light text">Salon Policies</h1>
        </Zoom>
        <Col
          xs={12}
          md={10}
          className="text-center mx-auto text-light my-3 py-4 policy-holder"
        >
          <Col xs={12} md={10} className="text-start mx-auto text-dark mb-5">
            <Zoom cascade={true} triggerOnce={true}>
              <p>
                We are committed to providing you with the best service
                possible. Please read through our policies to ensure that your
                experience is unique. If you have any questions please{" "}
                <Link to="/contact" title="Contact Colour Mi Chic">
                  contact us
                </Link>
                .
              </p>
            </Zoom>
          </Col>
          <Col xs={12} md={10} className="text-start text-dark mx-auto mb-5">
            <Zoom cascade={true} triggerOnce={true}>
              <h3 className="my-3 text-dark text-start fw-bold">
                Consultations
              </h3>
              <p>
                Every client will receive a consultation before any service.
                Full-color services must have a consultation and a basic service
                done prior to making an appointment for a full-color service.
                This is required to ensure that we have the opportunity to fully
                evaluate your hair needs and ensure that you understand the
                maintenance and commitment of color.
              </p>
            </Zoom>
          </Col>
          <Col xs={12} md={10} className="text-start text-dark mx-auto mb-5">
            <Zoom cascade={true} triggerOnce={true}>
              <h3 className="my-3 text-dark text-start fw-bold">Scheduling</h3>

              <p>
                We value all of our VIPs and understand that there is more to
                the service than just the style. We want to ensure that we help
                you achieve and maintain healthy, beautiful hair. That's why we
                encourage you to call and speak with us one-on-one in order to
                make sure that you are well informed and decide if we fit into
                your journey. You can schedule an appointment by{" "}
                <a
                  className="text-info"
                  href="tel:+17027281007"
                  title="Call Colour Mi Chic"
                >
                  phone
                </a>
                ,{" "}
                <a
                  className="text-info btn-link"
                  href="sms:+17027281007"
                  title="Text Colour Mi Chic"
                >
                  text
                </a>{" "}
                or{" "}
                <a
                  href="https://colour-mi-chic.square.site"
                  target="_blank"
                  rel="noreferrer"
                  title="Colour Mi Chic online booking"
                >
                  {" "}
                  online booking
                </a>
                <em>(when enabled)</em>.
              </p>
              <p>
                <em>
                  Due to the increased demand for customized styling and
                  services, online booking is currently disabled.
                </em>{" "}
              </p>
              <p>
                When online booking is available, please read the service
                description prior to booking. Please be advised that each online
                booking will require approval and you will receive a phone call
                to confirm your booking and outline the service/s you have
                requested and/or require.
              </p>
            </Zoom>
          </Col>
          <Col xs={12} md={10} className="text-start text-dark mx-auto mb-5">
            <Zoom cascade={true} triggerOnce={true}>
              <h3 className="my-3 text-dark text-start fw-bold">
                Late Cancellations & No-Shows
              </h3>

              <p>
                Notice of{" "}
                <span className="fw-bold text-danger">cancellation</span> must
                be given within 3 or more hours of an appointment. We do
                understand that there may be extenuating circumstance and
                unforseen events that may prevent you from adhering to this
                policy. For that reason, each cancellation that is made less
                than 3 hours before an appointment will be handled in a
                case-by-case basis and may result in you not being able to
                schedule an appointment.
              </p>

              <p>
                We value our time as well as yours. A{" "}
                <span className="fw-bold text-danger">no-show</span> may result
                in you not being able to schedule another appointment. If
                permitted to schedule another appointment, you will be required
                to pay for the missed service, in full, prior to scheduling an
                appointment.
              </p>
            </Zoom>
          </Col>
          <Col xs={12} md={10} className="text-start text-dark mx-auto mb-5">
            <Zoom cascade={true} triggerOnce={true}>
              <h3 className="my-3 text-dark text-start fw-bold">Prices</h3>

              <p>
                Prices are subject to change as the industry and economy
                changes. Not all services are listed. The list prices are base
                references only and the services are a la carte. Each crown is
                unique; therefore, services may be subject to additional charges
                depending on hair length, density, texture and the time and
                skill required to complete the service. Please{" "}
                <Link to="/contact" title="Contact Colour Mi Chic">
                  contact us
                </Link>{" "}
                for more information.
              </p>
            </Zoom>
          </Col>
          <Col xs={12} md={10} className="text-start text-dark mx-auto mb-5">
            <Zoom cascade={true} triggerOnce={true}>
              <h3 className="my-3 text-dark text-start fw-bold">Tardiness</h3>

              <p>
                In order to provide all clients with a personalized experience,
                we ask that you notify us if you are going to arrive late to
                your appointment. If you arrive more than 15mins late, you will
                be required to reschedule the appointment.
              </p>
            </Zoom>
          </Col>
          <Col xs={12} md={10} className="text-start text-dark mx-auto mb-5">
            <Zoom cascade={true} triggerOnce={true}>
              <h3 className="my-3 text-dark text-start fw-bold">Location</h3>

              <p>
                Colour Mi Chic is conveniently located less than a mile from the
                Galleria Mall and several retail stores. The premises has ample
                parking and privacy for your convenience.
              </p>
            </Zoom>
          </Col>
          <Col xs={12} md={10} className="text-start mx-auto text-dark mb-5">
            <Zoom cascade={true} triggerOnce={true}>
              <h3 className="my-3 text-dark text-start fw-bold">
                Salon Decorum
              </h3>

              <p className="">
                Your salon experience will be tailored to you and provide a safe
                space for you to relax and be pampered. In order for you to
                receive the the Chic experience, we ask that only individuals
                that are receiving services enter the salon, be mindful of
                talking on the phone when being serviced and keep it classy.
              </p>
            </Zoom>
          </Col>
          <Col xs={12} md={10} className="text-start text-dark mx-auto mb-5">
            <Zoom cascade={true} triggerOnce={true}>
              <h3 className="my-3 text-dark text-start fw-bold">Food</h3>

              <p>
                No outside food is allowed in the salon. We do offer
                refreshments, beverages and, occassionally, treats as part of
                the Chic experience.
              </p>
            </Zoom>
          </Col>
          <Col xs={12} md={10} className="text-start text-dark mx-auto mb-5">
            <Zoom cascade={true} triggerOnce={true}>
              <h3 className="my-3 text-dark text-start fw-bold">Pets</h3>

              <p>Pets are not allowed in the salon.</p>
            </Zoom>
          </Col>
          <Col xs={12} md={10} className="text-start text-dark mx-auto mb-5">
            <Zoom cascade={true} triggerOnce={true}>
              <h3 className="my-3 text-dark text-start fw-bold">Refunds</h3>

              <p>
                We do not offer refunds for services or products. If you are not
                satisfied please{" "}
                <Link to="/contact" title="Contact Colour Mi Chic">
                  contact us
                </Link>
                .
              </p>
            </Zoom>
          </Col>
        </Col>
      </Row>
    </>
  );
};

export default SalonPolicies;
