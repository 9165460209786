import {
  faMobileScreen,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col } from "reactstrap";
import "./Contact.css";

const Contacts = () => {
  return (
    <>
      <Row className="d-flex flex-row mb-xs-2 p-0 mx-auto">
        <Col className="d-flex flex-row mb-3 p-0">
          <div className="d-flex flex-row justify-content-center align-items-center icon-holder ">
            <FontAwesomeIcon
              icon={faLocationDot}
              className="ct-info ct-location "
            />{" "}
          </div>
          <div className="mx-0 ml-3">
            <h5 className="ct-info fw-bold text-start">Location</h5>
            <p className="text-start ct-info mb-0">
              1520 W Sunset Rd, <br />
              Building 130, Suite 106
              <br />
              Henderson, NV 89014
              <br />
            </p>
          </div>
        </Col>
      </Row>
      <Row className="d-flex flex-row mb-xs-2 p-0 mx-auto">
        <Col className="d-flex flex-row mb-0 p-0">
          <div className="d-flex flex-row justify-content-center align-items-center icon-holder">
            <FontAwesomeIcon
              icon={faMobileScreen}
              className="ct-info ct-mobile"
            />{" "}
          </div>
          <div className="mx-0 ml-3">
            <h5 className="ct-info fw-bold text-start">Call</h5>
            <p className="text-start">
              <a
                role="button"
                className="btn btn-link p-0 ct-info text-info text-decoration-none"
                href="tel:+17027281007"
              >
                1-702-728-1007
              </a>
            </p>
          </div>
        </Col>
      </Row>
      <Row className="d-flex flex-row mb-xs-2 p-0 mx-auto">
        <Col className="d-flex flex-row mb-0 p-0">
          <h5 className="text-danger">
            <em>By Appointment Only</em>
          </h5>
        </Col>
      </Row>
    </>
  );
};

export default Contacts;
