import React from "react";
import SalonPolcies from "../components/policies/SalonPolicies";
import { SEO } from "../shared/SEO";

const Policies = () => {
  return (
    <div className="container-fluid  w-100 p-2 p-md-4">
      {" "}
      <SEO
        title="Colour Mi' Chic Hair Salon | Salon Policies"
        description="description"
        content="Colour Mi' Chic salon policies"
      />
      <SalonPolcies />
    </div>
  );
};

export default Policies;
