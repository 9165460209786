import React from "react";
import { SEO } from "../shared/SEO";

const Promotions = () => {
  return (
    <div className="container-fluid  w-100 p-2 p-md-4">
      <SEO
        title="Colour Mi' Chic Hair Salon | Promotions"
        description="description"
        content="Colour Mi Chic promotions and specials"
      />
    </div>
  );
};

export default Promotions;
