import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import {
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cmcLogo from "../../shared/logos/chic-logo.svg";
import "./Header.css";

const Header = () => {
  const navLinks = ["book now","gallery", "menu", "contact"];

  const [openNav, setOpenNav] = useState(false);
  const setNav = () => setOpenNav(!openNav);
  const toggleNav = () =>
    openNav ? setOpenNav(!openNav) : setOpenNav(openNav);


  const setLink = ({ link }) => {
    if (link === "home") return "/";
    else if (link === "salon policies") return "/policies";
    // else if (link === "book now") return "https://colour-mi-chic.square.site";
    // } else if (link === "chic's corner") {
    //   return "/corner";
    // } else if (link === "health & beauty tips") {
    //   return "/health";
    else return `/${link}`;
  };

  const MenuItem = ({ to, linkName }) => {
    return (
      <NavItem>
        {/* */}
        <NavLink
          tag="link"
          onClick={toggleNav}
          className="nav-link text-capitalize hdr-link"
          to={to}
        >
          {linkName}
        </NavLink>
      </NavItem>
    );
  };

  const RenderLinks = () => {
    return navLinks.map((link, index) => {
      if (link === "book now") {
        return (
          <a
          href="https://colour-mi-chic.square.site"
          target="_blank"
          rel="noreferrer"
          title="Colour Mi Chic online booking"
          className="book-now nav-link"
          key={index}
        >
          Book Now
        </a>
        );
      } else {
        return <MenuItem to={setLink({ link })} linkName={link} key={index} />;
      } 
    });
  };

  return (
    <>
      <div className="hdr-banner container-fluid d-flex flex-row justify-content-between py-0 px-0 px-sm-2 px-md-4 mb-1">
        <div className="socials">
          <a
            href="https://www.instagram.com/colourmichic/"
            target="_blank"
            rel="noreferrer"
            aria-label="Instagram"
            className="btn btn-social-icon px-2 py-0 pt-1 hdr-banner-link"
          >
            <i
              className="fa fa-instagram fa-sm hdr-social m-auto"
              aria-hidden="true"
            />
          </a>
          <a
            href="https://www.facebook.com/colourmichic/"
            target="_blank"
            rel="noreferrer"
            aria-label="Facebook"
            className="btn btn-social-icon px-2 o py-0 pt-1 hdr-banner-link"
          >
            <i className="fa fa-facebook hdr-social" aria-hidden="true" />
          </a>
          <a
            href="https://colour-mi-chic.square.site"
            target="_blank"
            rel="noreferrer"
            title="Colour Mi Chic online booking"
            // change size of text
            className="btn px-2 py-0 pt-1 hdr-banner-link hdr-book text-light fw-bold "
          >
            {" "}
            Book Now
          </a>
        </div>
        <div className="hdr-contact px-2">
          <Link
            to="/contact"
            className="text-decoration-none d-flex flex-row btn px-2 py-0 pt-1 hdr-banner-link hdr-book text-light fw-bold "
            title="Link to Contact Page"
          >
            <span className="text-light hdr-envelope-link">
              <i className="fa fa-envelope mx-1" />
            </span>
            <span className="text-light align-middle fw-bold banner-contact">Contact Us</span>
          </Link>
        </div>
      </div>
      <div className="container-fluid px-0 nav-wrapper">
        <div className="site-nav container-fluid w-100 p-1 p-md-2">
          <Navbar expand="md" className="p-0 chicNav p-2 m-2 m-sm-3 m-md-4">
            <NavLink
              to="/"
              onClick={() =>
                openNav ? setOpenNav(!openNav) : setOpenNav(openNav)
              }
              className="text-decoration-none"
            >
              <img
                src={cmcLogo}
                alt="logo"
                className="header-logo chic-sm-logo"
              />
              <p className="mb-0 chic-sm-sub">Tailor&rsquo;d 2 U &#174;</p>
            </NavLink>
            <NavbarToggler onClick={setNav} className=" p-2">
              <FontAwesomeIcon icon={faBars} className="toggler-icon fa-xl" />
            </NavbarToggler>
            <Collapse
              isOpen={openNav}
              navbar
              className="justify-content-end px-0"
            >
              <Nav className="mr-auto" navbar>
                {/* map navLinks */}
                <UncontrolledDropdown direction="down" inNavbar nav>
                  <DropdownToggle nav caret className="text-capitalize">
                    About
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem className="text-center">
                      <NavLink
                        tag="link"
                        className="nav-link px-1"
                        to={`/salon`}
                      >
                        Salon
                      </NavLink>
                    </DropdownItem>
                    <DropdownItem className="text-center">
                      <NavLink
                        tag="link"
                        className="nav-link text-capitalize px-1"
                        to={`/policies`}
                      >
                        Salon Policies
                      </NavLink>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                {/* add booking here */}

                <RenderLinks />

                {/* <UncontrolledDropdown direction="down" inNavbar nav>
                  <DropdownToggle nav caret className="text-capitalize">
                    Chic's Corner
                  </DropdownToggle>
                  <DropdownMenu end>
                    <DropdownItem className="p-2 text-center">
                      <NavLink
                        tag="link"
                        className="nav-link text-capitalize p-0"
                        to={`/health`}
                      >
                        Health & Beauty Tips
                      </NavLink>
                    </DropdownItem>
                    <DropdownItem className="p-2 text-center text-md-start">
                      <NavLink
                        tag="link"
                        className="nav-link text-capitalize p-0"
                        to={`/promotions`}
                      >
                        Promotions
                      </NavLink>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown> */}
              </Nav>
            </Collapse>
          </Navbar>
        </div>
      </div>
    </>
  );
};

export default Header;
