import cmcLogo from "../../shared/logos/lg-logo.svg";
import { Row, Col } from "reactstrap";
import "./Home.css";

const Intro = () => {
  return (
    <div className="justify-content-around p-2 logo-row">
      <Row className="text-center p-0">
        <img src={cmcLogo} alt="logo" className=" mx-auto chic-logo" />
      </Row>
      <Col>
        <h1 className="mb-3 chic-sub">Tailor&rsquo;d 2 U &#174;</h1>
      </Col>
    </div>
  );
};

export default Intro;
