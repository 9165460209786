/*
// https://i.ibb.co/f1gh9VZ/IMG-0693.jpg
// https://i.ibb.co/5LG5TR9/IMG-0769.jpg
// https://i.ibb.co/wScZr6f/IMG-1041.jpg
// https://i.ibb.co/VQrrNx9/IMG-1564.jpg
// https://i.ibb.co/f24zZqq/IMG-2247.jpg
// https://i.ibb.co/Sy3ZVkS/IMG-3138.jpg
// https://i.ibb.co/LtG3Qjc/IMG-3270.jpg
// https://i.ibb.co/XVZs8GY/IMG-3600.jpg
// https://i.ibb.co/pWYZqmw/IMG-3795.jpg
// https://i.ibb.co/HTMVgBs/IMG-6035.jpg
// https://i.ibb.co/sQPzGYM/IMG-7010.jpg
// https://i.ibb.co/fqsrrhM/IMG-7248.jpg
// https://i.ibb.co/n1BhTHJ/IMG-7824.jpg
// https://i.ibb.co/9Vqqb1W/IMG-7877.jpg
// https://i.ibb.co/1z6xp9d/IMG-9321.jpg
// https://i.ibb.co/9NgTbgF/img1.jpg
// https://i.ibb.co/s5MY6G4/img2.jpg
// https://i.ibb.co/tzhwF1f/img3.jpg
*/

export const IMAGES = [
  {
    itemId: 0,
    mediaUrl: "assets/images/IMG_0447.JPG",
    title: "sample style image",
    description: "sample style image",
  },
  {
    itemId: 1,
    mediaUrl: "assets/images/IMG_0761.JPG",
    title: "sample style image",
    description: "sample style image",
  },
  {
    itemId: 2,
    mediaUrl: "assets/images/IMG_0769a.JPG",
    title: "sample style image",
    description: "sample style image",
  },
  {
    itemId: 3,
    mediaUrl: "assets/images/IMG_0769b.jpg",
    title: "sample style image",
    description: "sample style image",
  },
  {
    itemId: 4,
    mediaUrl: "assets/images/IMG_1034.JPG",
    title: "sample style image",
    description: "sample style image",
  },
  {
    itemId: 5,
    mediaUrl: "assets/images/IMG_1191.JPG",
    title: "sample style image",
    description: "sample style image",
  },
  {
    itemId: 6,
    mediaUrl: "assets/images/IMG_1326.JPG",
    title: "sample style image",
    description: "sample style image",
  },
  {
    itemId: 7,
    mediaUrl: "assets/images/IMG_1564.JPG",
    title: "sample style image",
    description: "sample style image",
  },
  {
    itemId: 8,
    mediaUrl: "assets/images/IMG_1565.JPG",
    title: "sample style image",
    description: "sample style image",
  },
  {
    itemId: 9,
    mediaUrl: "assets/images/IMG_1566.JPG",
    title: "sample style image",
    description: "sample style image",
  },
  {
    itemId: 10,
    mediaUrl: "assets/images/IMG_1567.JPG",
    title: "sample style image",
    description: "sample style image",
  },
  {
    itemId: 11,
    mediaUrl: "assets/images/IMG_1570.JPG",
    title: "sample style image",
    description: "sample style image",
  },
  {
    itemId: 12,
    mediaUrl: "assets/images/IMG_1690.JPG",
    title: "sample style image",
    description: "sample style image",
  },
  {
    itemId: 13,
    mediaUrl: "assets/images/IMG_1702.JPG",
    title: "sample style image",
    description: "sample style image",
  },
  {
    itemId: 14,
    mediaUrl: "assets/images/IMG_1742.JPG",
    title: "sample style image",
    description: "sample style image",
  },
  {
    itemId: 15,
    mediaUrl: "assets/images/IMG_1748.JPG",
    title: "sample style image",
    description: "sample style image",
  },
  {
    itemId: 16,
    mediaUrl: "assets/images/IMG_1750.JPG",
    title: "sample style image",
    description: "sample style image",
  },
  {
    itemId: 17,
    mediaUrl: "assets/images/IMG_1751.JPG",
    title: "sample style image",
    description: "sample style image",
  },
  {
    itemId: 18,
    mediaUrl: "assets/images/IMG_1763.JPG",
    title: "sample style image",
    description: "sample style image",
  },
  {
    itemId: 19,
    mediaUrl: "assets/images/IMG_1790.JPG",
    title: "sample style image",
    description: "sample style image",
  },
  {
    itemId: 20,
    mediaUrl: "assets/images/IMG_1794.JPG",
    title: "sample style image",
    description: "sample style image",
  },
  {
    itemId: 21,
    mediaUrl: "assets/images/IMG_1865.JPG",
    title: "sample style image",
    description: "sample style image",
  },
  {
    itemId: 22,
    mediaUrl: "assets/images/IMG_1913.JPG",
    title: "sample style image",
    description: "sample style image",
  },
  {
    itemId: 23,
    mediaUrl: "assets/images/IMG_1914.JPG",
    title: "sample style image",
    description: "sample style image",
  },
  {
    itemId: 24,
    mediaUrl: "assets/images/IMG_1915.JPG",
    title: "sample style image",
    description: "sample style image",
  },
  {
    itemId: 25,
    mediaUrl: "assets/images/IMG_2061.JPG",
    title: "sample style image",
    description: "sample style image",
  },
  {
    itemId: 26,
    mediaUrl: "assets/images/IMG_2062.JPG",
    title: "sample style image",
    description: "sample style image",
  },
  {
    itemId: 27,
    mediaUrl: "assets/images/IMG_2066.JPG",
    title: "sample style image",
    description: "sample style image",
  },
  {
    itemId: 27,
    mediaUrl: "assets/images/IMG_2218.JPG",
    title: "sample style image",
    description: "sample style image",
  },
  {
    itemId: 28,
    mediaUrl: "assets/images/IMG_2222.JPG",
    title: "sample style image",
    description: "sample style image",
  },
  {
    itemId: 29,
    mediaUrl: "assets/images/IMG_2231.JPG",
    title: "sample style image",
    description: "sample style image",
  },
  {
    itemId: 30,
    mediaUrl: "assets/images/IMG_2232.JPG",
    title: "sample style image",
    description: "sample style image",
  },
  {
    itemId: 31,
    mediaUrl: "assets/images/IMG_2246.JPG",
    title: "sample style image",
    description: "sample style image",
  },
  {
    itemId: 32,
    mediaUrl: "assets/images/IMG_2247a.JPG",
    title: "sample style image",
    description: "sample style image",
  },
  {
    itemId: 33,
    mediaUrl: "assets/images/IMG_2247b.JPG",
    title: "sample style image",
    description: "sample style image",
  },
  {
    itemId: 34,
    mediaUrl: "assets/images/IMG_2633.JPG",
    title: "sample style image",
    description: "sample style image",
  },
  {
    itemId: 35,
    mediaUrl: "assets/images/IMG_2984.JPG",
    title: "sample style image",
    description: "sample style image",
  },
  {
    itemId: 36,
    mediaUrl: "assets/images/IMG_3049.JPG",
    title: "sample style image",
    description: "sample style image",
  },
  {
    itemId: 37,
    mediaUrl: "assets/images/IMG_3051.JPG",
    title: "sample style image",
    description: "sample style image",
  },
  {
    itemId: 38,
    mediaUrl: "assets/images/IMG_3138.jpg",
    title: "sample style image",
    description: "sample style image",
  },
  {
    itemId: 39,
    mediaUrl: "assets/images/IMG_3270.jpg",
    title: "sample style image",
    description: "sample style image",
  },
  {
    itemId: 40,
    mediaUrl: "assets/images/IMG_3795.jpg",
    title: "sample style image",
    description: "sample style image",
  },
  {
    itemId: 41,
    mediaUrl: "assets/images/IMG_3857.JPG",
    title: "sample style image",
    description: "sample style image",
  },
  {
    itemId: 42,
    mediaUrl: "assets/images/IMG_3859.JPG",
    title: "sample style image",
    description: "sample style image",
  },
  {
    itemId: 43,
    mediaUrl: "assets/images/IMG_5457.JPG",
    title: "sample style image",
    description: "sample style image",
  },
  {
    itemId: 44,
    mediaUrl: "assets/images/IMG_5464.JPG",
    title: "sample style image",
    description: "sample style image",
  },
  {
    itemId: 45,
    mediaUrl: "assets/images/IMG_5467.JPG",
    title: "sample style image",
    description: "sample style image",
  },
  {
    itemId: 46,
    mediaUrl: "assets/images/IMG_7248.JPG",
    title: "sample style image",
    description: "sample style image",
  },
  {
    itemId: 47,
    mediaUrl: "assets/images/IMG_07659.JPG",
    title: "sample style image",
    description: "sample style image",
  },
  {
    itemId: 48,
    mediaUrl: "assets/images/IMG_07660.JPG",
    title: "sample style image",
    description: "sample style image",
  },
  {
    itemId: 49,
    mediaUrl: "assets/images/IMG_07691.JPG",
    title: "sample style image",
    description: "sample style image",
  },
  {
    itemId: 50,
    mediaUrl: "assets/images/IMG_7812.JPG",
    title: "sample style image",
    description: "sample style image",
  },
  {
    itemId: 51,
    mediaUrl: "assets/images/IMG_7815.JPG",
    title: "sample style image",
    description: "sample style image",
  },
  {
    itemId: 52,
    mediaUrl: "assets/images/IMG_7824.jpg",
    title: "sample style image",
    description: "sample style image",
  },
  {
    itemId: 53,
    mediaUrl: "assets/images/IMG_9317.JPG",
    title: "sample style image",
    description: "sample style image",
  },
  {
    itemId: 54,
    mediaUrl: "assets/images/IMG_9321.jpg",
    title: "sample style image",
    description: "sample style image",
  },
  {
    itemId: 55,
    mediaUrl: "/assets/images/IMG_9322.JPG",
    title: "sample style image",
    description: "sample style image",
  },
  {
    itemId: 56,
    mediaUrl: "/assets/images/IMG_9323.JPG",
    title: "sample style image",
    description: "sample style image",
  },
  {
    itemId: 57,
    mediaUrl: "assets/images/IMG_9324.JPG",
    title: "sample style image",
    description: "sample style image",
  },
  {
    itemId: 58,
    mediaUrl: "assets/images/IMG_9957.JPG",
    title: "sample style image",
    description: "sample style image",
  },
];

export const CHIC = "assets/images/IMG_90819.jpg";
export const CHIC2 = "assets/images/img_chic_one.jpg";
export const CHIC3 = "assets/images/img_chic_two.jpg";
export const silouetteFour = "assets/images/hair-silo-four.png";
export const nycSkyline = "assets/images/nyc-2.png";
export const nycSkyline2 = "assets/images/nyc-3.jpg";

export const SALON = [
  {
    itemId: 1,
    mediaUrl: "assets/images/img_chic_two.jpg",
    title: "salon image",
    description: "salon image",
  },
  {
    itemId: 2,
    mediaUrl: "assets/images/IMG_90820.jpg",

    title: "salon image",
    description: "salon image",
  },
  {
    itemId: 3,
    mediaUrl: "assets/images/salon-1.jpg",
    title: "salon image",
    description: "salon image",
  },
  {
    itemId: 4,
    mediaUrl: "assets/images/salon-3.jpg",
    title: "salon image",
    description: "salon image",
  },
  {
    itemId: 5,
    mediaUrl: "assets/images/IMG_90926.jpg",
    title: "salon image",
    description: "salon image",
  },
  {
    itemId: 6,
    mediaUrl: "assets/images/salon-2.jpg",
    title: "salon image",
    description: "salon image",
  },
  {
    itemId: 7,
    mediaUrl: "assets/images/img_chic_one.jpg",
    title: "salon image",
    description: "salon image",
  },
  {
    itemId: 8,
    mediaUrl: "assets/images/salon-5.jpg",
    title: "salon image",
    description: "salon image",
  },
  // {
  //   itemId: 13,
  //   mediaUrl: "assets/images/IMG_90950.jpg",
  //   title: "salon image",
  //   description: "salon image",
  // },
];
