import { useState, useEffect } from "react";
import { firstBy } from "thenby";
// import { Row, Col, Spinner } from "reactstrap";
import axios from "./components/api/AxiosApi";
import { Routes, Route } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
// import ScrollToTop from "react-scroll-to-top";
import Layout from "./components/Layout";
import About from "./pages/About";
import Policies from "./pages/Policies";
import Gallery from "./pages/Gallery";
import Home from "./pages/Home";
import Services from "./pages/Services";
import Contact from "./pages/Contact";
import ChicsCorner from "./pages/ChicsCorner";
import Health from "./pages/Health";
import Promotions from "./pages/Promotions";
import "./App.css";

const SERVICE_URL = process.env.REACT_APP_SERVICES_URL;

function App() {
  const [services, setServices] = useState([]);
  const [types, setTypes] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const getServices = async () => {
      try {
        const response = await axios
          .get(SERVICE_URL)
          .then((response) =>
            response.data.sort(
              firstBy("service_type", { ignoreCase: true }).thenBy(
                "service_name",
                { ignoreCase: true }
              )
            )
          );
        setServices(response);
        setLoading(false);

        // get all of the unique service types
        const serviceTypes = response.map((service) => service.service_type);
        const uniqueServiceTypes = [...new Set(serviceTypes)];
        setTypes(uniqueServiceTypes);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    getServices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <HelmetProvider>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="/" element={<Home />} />
            <Route path="salon" element={<About />} />
            <Route path="policies" element={<Policies />} />
            <Route path="gallery" element={<Gallery />} />
            <Route
              path="menu"
              element={
                <Services services={services} types={types} loading={loading} />
              }
            />
            <Route path="contact" element={<Contact />} />
            <Route path="corner" element={<ChicsCorner />} />
            <Route path="health" element={<Health />} />
            <Route path="promotions" element={<Promotions />} />
          </Route>
        </Routes>
      </HelmetProvider>
    </>
  );
}

export default App;
