import ContactForm from "../components/contact/ContactForm";
import { SEO } from "../shared/SEO";
import "../components/contact/Contact.css";

const Contact = () => {
  return (
    <div className="container-fluid  w-100 p-1 p-md-3 c-header">
      <SEO
        title="Colour Mi' Chic Hair Salon | Contact"
        description="description"
        content="Contact Colour Mi' Chic"
      />
      <ContactForm />
    </div>
  );
};

export default Contact;
