import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Link } from "react-router-dom";

export const SiteCrumbs = ({ siteLocation }) => {
  return (
    <Breadcrumb className="m-2">
      <BreadcrumbItem>
        <Link className="text-light" to="/">
          Home
        </Link>
      </BreadcrumbItem>
      <BreadcrumbItem>{siteLocation}</BreadcrumbItem>
    </Breadcrumb>
  );
};
