import { Outlet, useLocation } from "react-router-dom";
import { SiteCrumbs } from "./header/SiteCrumbs";
import ScrollToTop from "react-scroll-to-top";
import { Row } from "reactstrap";
import Header from "./header/Header";
import Footer from "./footer/Footer";
import "../App.css";

const Layout = () => {
  // get the location from the url
  // eslint-disable-next-line no-unused-vars
  const { location } = useLocation();

  //  function to only show SiteCrumbs if the location is not /, otherwise do not display SiteCrumbs
  const RenderSiteCrumbs = () => {
    if (window.location.pathname !== "/") {
      // if location is /about, set siteLocation to About
      if (window.location.pathname === "/salon")
        return <SiteCrumbs siteLocation="Salon" />;
      else if (window.location.pathname === "/policies")
        return <SiteCrumbs siteLocation="Salon Policies" />;
      else if (window.location.pathname === "/contact")
        return <SiteCrumbs siteLocation="Contact" />;
      else if (window.location.pathname === "/gallery")
        return <SiteCrumbs siteLocation="Gallery" />;
      else if (window.location.pathname === "/menu")
        return <SiteCrumbs siteLocation="Menu" />;
      else if (window.location.pathname === "/health")
        return <SiteCrumbs siteLocation="Health & Beauty Tips" />;
      else if (window.location.pathname === "/promotions")
        return <SiteCrumbs siteLocation="Promotions" />;
      // return <SiteCrumbs siteLocation={window.location.pathname} />
    }
  };

  return (
    <div className="App container-fluid px-0">
      <ScrollToTop smooth color="#ffffff" />
      {/* <div className="inner-app p-0 p-sm-2"> */}
      <div className="inner-app p-0">
        <div className="w-100 container-fluid p-0">
          <Header />
        </div>
        {/* <SiteCrumbs siteLocation='Home' /> */}
        <RenderSiteCrumbs />
        <div className="chic mt-3 p-2" style={{ height: "calc(100% - 148px)" }}>
          {/* <RenderSiteCrumbs /> */}
          <div className="w-100 container-fluid p-0 p-sm-3 p-md-4 m-0 mb-4 inner-wrapper">
            <Outlet />
          </div>
          <Row className="w-100 container-fluid p-0 footer-wrapper">
            <Footer />
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Layout;
