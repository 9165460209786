import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min";
import "../node_modules/bootswatch/dist/spacelab/bootstrap.min.css";
import "../node_modules/font-awesome/css/font-awesome.min.css";
import "typeface-dancing-script";
import "@fontsource/urbanist";
import "@fontsource/noto-sans-display";
import "@fontsource/prata";
import "@fontsource/lato";
import "@fontsource/gotu";
import "@fontsource/marcellus";
import "@fontsource/dm-serif-display";
import "@fontsource/della-respira";
import "@fontsource/raleway";
import "@fontsource/montserrat";

import App from "./App";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <ScrollToTop />
    <Routes>
      <Route path="/*" element={<App />} />
    </Routes>
    <ScrollToTop />
  </BrowserRouter>
);
