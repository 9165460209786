import React, { useState } from "react";
import { IMAGES } from "../../shared/images";
import { Row, Col, Carousel, CarouselItem, CarouselControl } from "reactstrap";
import { Zoom } from "react-awesome-reveal";
import "./GalleryLayout.css";

export default function GalleryLayout() {
  const items = IMAGES;

  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const slides = items.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.mediaUrl}
        className=" p-0 p-sm-2 p-md-3"
      >
        <img
          src={process.env.PUBLIC_URL + item.mediaUrl}
          alt={item.title}
          className="img-fluid gallery-img"
        />
      </CarouselItem>
    );
  });

  return (
    <Row className="gallery-row p-2 p-sm-3 m-0 m-2 m-md-3">
      <Zoom cascade={true} triggerOnce={true}>
        <h1 className="text-light my-4">Gallery</h1>
      </Zoom>
      <Col md={10} className="p-2 p-md-3 img-wrapper mx-auto mb-4">
        <Zoom cascade={true} triggerOnce={true}>
          <Carousel
            fade={true}
            activeIndex={activeIndex}
            next={next}
            previous={previous}
          >
            {/* <CarouselIndicators
          items={items}
          activeIndex={activeIndex}
          onClickHandler={goToIndex}
        /> */}
            {slides}
            <CarouselControl
              direction="prev"
              directionText="Previous"
              onClickHandler={previous}
            />
            <CarouselControl
              direction="next"
              directionText="Next"
              onClickHandler={next}
            />
          </Carousel>
        </Zoom>
      </Col>
    </Row>
  );
}
