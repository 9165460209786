import Homepage from "../components/home/Homepage";
import { SEO } from "../shared/SEO";

const Home = () => {
  return (
    <>
      <SEO
        title="Colour Mi' Chic Hair Salon"
        description="description"
        content="Colour Mi' Chic website home page"
      />
      <Homepage />
    </>
  );
};

export default Home;
