import React from "react";
import { SEO } from "../shared/SEO";

const Health = () => {
  return (
    <div className="container-fluid w-100 p-2 p-md-4">
      <SEO
        title="Colour Mi' Chic Hair Salon | Health & Beauty Tips"
        description="description"
        content="Free Health & Beauty Tips from Colour Mi' Chic"
      />
    </div>
  );
};

export default Health;
