import { Row, Col, NavItem, Table } from "reactstrap";
import { NavLink } from "react-router-dom";
import {
  faLocationDot,
  faMobileScreen,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Footer.css";

function FooterLink({ className, navClass, to, title }) {
  return (
    <NavItem className="text-center">
      <NavLink
        className="text-center text-capitalize list-style-none ft-link"
        to={to}
      >
        {title}
      </NavLink>
    </NavItem>
  );
}

const Footer = () => {
  const footerLinks = [
    "book now",
    "salon",
    "salon policies",
    "gallery",
    "menu",
    "contact",
    // "health & beauty tips",
    // "promotions",
  ];

  const setFooterLink = ({ link }) => {
    if (link === "home") return "/";
    else if (link === "about") return "/salon";
    else if (link === "salon policies") return "/policies";
    else if (link === "gallery") return "/gallery";
    else if (link === "menu") return "/menu";
    // } else if (link === "health & beauty tips") {
    //   return "/health";
    else return `/${link}`;
  };

  const RenderFooterLinks = () => {
    return footerLinks.map((link, index) => {
      if (link === "book now") {
        return (
          <a
          href="https://colour-mi-chic.square.site"
          target="_blank"
          rel="noreferrer"
          title="Colour Mi Chic online booking"
          className="ft-link"
          key={index}
        >
          Book Now
        </a>
        );
      } else {
        return (
          <FooterLink to={setFooterLink({ link })} title={link} key={index} />
        );
      }
    });
  };

  const year = new Date().getFullYear();

  return (
    <footer className="site-footer container-fluid px-0 ">
      <div className="container-fluid w-100 p-2 p-md-3">
        <Row className="p-0 m-1 m-sm-3 footer-row">
          <Col lg={4} className="mt-3 text-center p-0">
            <h3 className="my-2 mb-3 text-light ft-heading">CONTACT</h3>
            <h5 className=" text-light ft-heading">
              <FontAwesomeIcon icon={faLocationDot} className="mr-2 fa-xl" />{" "}
              Location
            </h5>
            <address className="text-light ft-info">
              1520 W Sunset Rd, <br />
              Building 130, Suite 106
              <br />
              Henderson, NV 89014
              <br />
            </address>
            {/* <p className='mb-0 text-light ft-heading'><u className='u-line '>Phone & Email</u></p> */}
            <p className=" text-light ft-heading">
              <a
                role="button"
                className="btn btn-link p-0 text-light ft-info text-decoration-none"
                href="tel:+17027281007"
              >
                <FontAwesomeIcon
                  icon={faMobileScreen}
                  className="fa-xl ft-social"
                />{" "}
                1-702-728-1007
              </a>
              <br />
            </p>

            <p className=" text-light ft-heading">
              <a
                href="https://www.instagram.com/colourmichic/"
                target="_blank"
                rel="noreferrer"
                aria-label="Instagram"
                className="btn btn-social-icon pt-md-0 pl-0"
              >
                <i
                  className="fa fa-instagram fa-xl ft-social"
                  aria-hidden="true"
                />
              </a>
              <a
                href="https://www.facebook.com/colourmichic/"
                target="_blank"
                rel="noreferrer"
                aria-label="Facebook"
                className="btn btn-social-icon pt-md-0 pl-0"
              >
                <i
                  className="fa fa-facebook fa-xl ft-social"
                  aria-hidden="true"
                />
              </a>
            </p>
          </Col>

          {/* Vertical Nav */}
          <Col lg={4} className="mt-3 p-md-0">
            <Col className="mx-auto mx-md-0 mb-4 p-xs-3 p-md-0 px-md-auto">
              <h3 className="my-2 mb-3 text-light text-center ft-heading">
                SITE MAP
              </h3>
              <ul className="p-0">
                <RenderFooterLinks />
              </ul>
              {/* <RenderFooterLinks /> */}
            </Col>
          </Col>

          {/* Social Media */}
          <Col lg={4} className="mt-3 text-center p-0">
            <Col xs={10} className="mx-auto  text-center p-0">
              <h3 className="my-2 mb-3 text-light ft-heading">HOURS</h3>
              <Table className="text-light ft-info" responsive>
                <tbody>
                  <tr>
                    <td>Sunday</td>
                    <td>Closed</td>
                  </tr>
                  <tr>
                    <td>Monday</td>
                    <td>Closed</td>
                  </tr>
                  <tr>
                    <td>Tuesday</td>
                    <td>
                      <em>
                        <span className="text-danger">*</span>BAO
                      </em>
                    </td>
                  </tr>
                  <tr>
                    <td>Wednesday</td>
                    <td>10AM - 8PM</td>
                  </tr>
                  <tr>
                    <td>Thursday</td>
                    <td>10AM - 8PM</td>
                  </tr>
                  <tr>
                    <td>Friday</td>
                    <td>10AM - 8PM</td>
                  </tr>
                  <tr>
                    <td>Saturday</td>
                    <td>8AM - 3PM</td>
                  </tr>
                </tbody>
              </Table>
              <p>
                <em>
                  <span className="text-danger">*</span>By Appointment Only
                </em>
              </p>
            </Col>
          </Col>
          <Col md={12} className="p-0 mt-3">
            <p className="copyright text-center text-light mb-0">
              &copy; {year} Colour Mi' Chic | Created by Ginx Collective
              <br />
            </p>
          </Col>
          {/* the bar must remain at the bottom 0 */}
          {/* <Col className="sticky-bar d-md-none bg-green">
            <a
              href="https://colour-mi-chic.square.site"
              target="_blank"
              rel="noreferrer"
              title="Colour Mi Chic online booking"
              className="sticky-link bg-pink"
            >
              Book Now
            </a>
          </Col> */}
        </Row>
      </div>
      {/* create a sticky bar at the bottom that only appears with the  screen size is less than 768 */}
      
    </footer>
  );
};

export default Footer;
