import GalleryLayout from "../components/gallery/GalleryLayout";
import { SEO } from "../shared/SEO";

const Gallery = () => {
  return (
    <div className=" container-fluid w-100 p-2 p-md-4 gallery-bg">
      <SEO
        title="Colour Mi' Chic Hair Salon | Gallery"
        description="description"
        content="Images of hairstyles created by Colour Mi' Chic"
      />
      <GalleryLayout />
    </div>
  );
};

export default Gallery;
