import emailjs from "emailjs-com";
// import Zoom from "react-reveal/Zoom";
import ContactMethods from "./ContactMethods";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Row, Col } from "reactstrap";
import { Zoom } from "react-awesome-reveal";
import "./Contact.css";

/*

Move errors to the left of form. Render send message to includd senders name
We will get back to you within 1 business day of the salon hours of operation.

Do SEO for contact page
 */

const ContactForm = () => {
  const [senderName, setSenderName] = useState("");
  const [success, setSuccess] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const resetForm = () => {
    // reset form and set success to false after 3.5 seconds
    reset();
    setTimeout(() => {
      setSuccess(false);
      setIsDisabled(false);
    }, 8500);
  };

  const sendEmail = (formData) => {
    emailjs
      .send(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        formData,
        process.env.REACT_APP_USER_ID
      )
      .then(
        (result) => {
          setSenderName(formData.name);
          setSuccess(true);
          setIsDisabled(true);
          resetForm();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <>
      <Row className="d-flex flex-column flex-md-row p-2 p-sm-3 m-0 m-2 m-md-3 c-form contact-row">
        <Col xs={12} md={10} className="m-auto">
          <Zoom cascade={true} triggerOnce={true}>
            <h1 className="my-4 text-light">Contact Us</h1>
          </Zoom>
        </Col>
        <Col
          xs={12}
          md={10}
          className="d-flex flex-column flex-lg-row py-4 px-md-3 pb-4 justify-content-around mx-auto contact-holder"
        >
          <Col xs={12} lg={5} className="mb-3 p-0 align-self-md-center">
            <Zoom cascade={true} triggerOnce={true}>
              <ContactMethods />
            </Zoom>
          </Col>
          <Col lg={6} className="p-0">
            <Zoom cascade={true} triggerOnce={true}>
              <form
                onSubmit={handleSubmit(sendEmail)}
                className="p-2 contact-form"
              >
                <Row className="p-3 d-flex">
                  <Col xs={12} className=" mb-3 px-0 text-center">
                    {!success && (
                      <p className="mb-0 py-2 text-light ">
                        Fill out the form below to send me a message.
                      </p>
                    )}
                    {success && (
                      <Zoom cascade={true} triggerOnce={true}>
                        <p className="text-light form-success p-2">
                          Thank you,{" "}
                          <span className="text-capitalize">{senderName}</span>.{" "}
                          <br />
                          We will get back to you within 1 business day of the
                          salon hours of operation.{" "}
                          <em>Please remember to check your spam folder</em>.
                        </p>
                      </Zoom>
                    )}
                  </Col>
                  <Col
                    md={6}
                    className="form-group mb-1 mb-md-3 px-0 px-md-1 py-1 text-start"
                  >
                    <label className="form-label text-light">
                      Name<span className="text-danger"> *</span>
                    </label>
                    <input
                      className=" form-control contact-input px-1 pr-1 "
                      htmlFor="name"
                      aria-label="name"
                      id="name"
                      name="name"
                      type="text"
                      {...register("name", {
                        required: {
                          value: true,
                          message: "Name is required.",
                        },
                        pattern: {
                          value: /^[a-zA-Z ]*$/,
                          message: "Name must be letters only.",
                        },
                        minLength: {
                          value: 3,
                          message: "Name must be at least 3 characters.",
                        },
                        maxLength: {
                          value: 50,
                          message: "Name must be less than 50 characters.",
                        },
                      })}
                      autoComplete="off"
                    />
                    {errors.name && (
                      <p className="mb-0 p-1 text-danger fw-bold text-start err">
                        {errors.name.message}
                      </p>
                    )}
                  </Col>
                  <Col
                    md={6}
                    className="form-group col-md-4 mb-1 mb-md-3 px-0 px-md-1 py-1 text-start"
                  >
                    <label className="form-label text-light ">
                      Email<span className="text-danger"> *</span>
                    </label>
                    <input
                      className=" form-control contact-input px-1 pr-1"
                      htmlFor="email"
                      aria-label="email"
                      id="email"
                      name="email"
                      type="email"
                      {...register("email", {
                        required: {
                          value: true,
                          message: "Email is required.",
                        },
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          message: "Email must be valid.",
                        },
                      })}
                      autoComplete="off"
                    />
                    {/* <Fade bottom> */}
                    {errors.email && (
                      <p className="mb-0 p-1 text-danger text-start fw-bold err">
                        {errors.email.message}
                      </p>
                    )}
                  </Col>
                  <Col
                    md={6}
                    className="form-group col-md-4 mb-1 mb-md-3 px-0 px-md-1 py-1 text-start"
                  >
                    <label className="form-label text-light ">
                      Phone <span className="text-danger"> *</span>
                    </label>
                    <input
                      className=" form-control contact-input px-1 pr-1"
                      htmlFor="phone"
                      aria-label="phone"
                      id="phone"
                      name="phone"
                      type="tel"
                      {...register("phone", {
                        required: {
                          value: true,
                          message: "Phone is required.",
                        },
                        maxLength: 12,
                        pattern: {
                          value:
                            /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/i,
                          // ((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}"/i

                          message: "Phone must be valid.",
                        },
                      })}
                      autoComplete="off"
                    />
                    {/* <Fade bottom> */}
                    {errors.phone && (
                      <p className="mb-0 p-1 text-danger fw-bold text-start err">
                        {errors.phone.message}
                      </p>
                    )}
                  </Col>

                  <Col
                    md={6}
                    className="form-group col-md-4 mb-1 mb-md-3 px-0 px-md-1 py-1 text-start"
                  >
                    <label className="form-label text-light ">
                      Subject<span className="text-danger"> *</span>
                    </label>
                    <input
                      className="form-control contact-input px-1 pr-1"
                      htmlFor="subject"
                      aria-label="subject"
                      name="subject"
                      id="subject"
                      type="text"
                      {...register("subject", {
                        required: {
                          value: true,
                          message: "Subject is required.",
                        },
                        minLength: {
                          value: 3,
                          message: "Subject must be at least 3 characters.",
                        },
                        maxLength: {
                          value: 20,
                          message: "Subject must be less than 20 characters.",
                        },
                      })}
                      autoComplete="off"
                    />
                    {/* <Fade bottom> */}
                    {errors.subject && (
                      <p className="mb-0 p-1 text-danger text-start fw-bold err">
                        {errors.subject.message}
                      </p>
                    )}
                    {/* </Fade> */}
                  </Col>
                  {/* </Col> */}
                  <Col className="form-group col-md-12 mb-1 mb-md-3 px-0 px-md-1 py-1 text-start">
                    <label className="form-label text-light  text-start">
                      Message<span className="text-danger"> *</span>
                    </label>
                    <textarea
                      className="form-control contact-input mb-1 px-1 pr-1 textBg "
                      htmlFor="message"
                      aria-label="message"
                      type="textarea"
                      id="message"
                      name="message"
                      rows="4"
                      {...register("message", {
                        required: {
                          value: true,
                          message: "Message is required.",
                        },
                        minLength: {
                          value: 5,
                          message: "Message must be at least 5 characters.",
                        },
                        maxLength: {
                          value: 500,
                          message: "Message must be less than 500 characters.",
                        },
                      })}
                    />
                    {/* <Fade bottom> */}
                    {errors.message && (
                      <p className="mb-0 p-1 text-danger fw-bold text-start err">
                        {errors.message.message}
                      </p>
                    )}
                    {/* </Fade> */}
                  </Col>
                  <div className="form-group d-grid px-0 px-md-1 py-1">
                    <button
                      className="btn sendBtn btn-lg btn-block"
                      type="submit"
                      disabled={isDisabled}
                    >
                      Submit
                    </button>
                  </div>
                </Row>
              </form>
            </Zoom>
          </Col>
        </Col>
      </Row>
    </>
  );
};

export default ContactForm;
