import Info from "../components/about/Info";
import { SEO } from "../shared/SEO";
const About = () => {
  return (
    <div className="container-fluid w-100 p-2 p-md-4">
      <SEO
        title="Colour Mi' Chic Hair Salon | About "
        description="description"
        content="About Colour Mi' Chic"
      />
      <Info />
    </div>
  );
};

export default About;
